<template>
  <div class="md-layout-item md-size-100">
    <SkillLevelUpPricesTable />
  </div>
</template>

<script>
export default {
  components: {
    SkillLevelUpPricesTable: () =>
      import("@/components/Tables/SkillLevelUpPricesTable"),
  },
};
</script>
